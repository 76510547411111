'use client'

import { useEffect, useState, useRef } from 'react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { ArrowUpRight, Users, TrendingUp, Clock, MapPin, Globe, Flag, PartyPopper, Contact, Speech, LandPlot } from 'lucide-react';
import ConstituencySearch from './ConstituencySearch';

interface PetitionData {
  count: number
  timestamp: number
}

interface SignatureData {
  name: string
  signature_count: number
}

interface ConstituencyData extends SignatureData {
  ons_code: string
  population: number
  percentage: number
  mp: string
  party: string
}

interface PopulationData {
  [ons_code: string]: {
    population: number;
  };
}

interface MPData {
  [ons_code: string]: {
    mp: string;
    party: string;
  };
}

interface PartyData {
  party: string,
  count: number,
}

export default function PetitionTracker() {
  const [data, setData] = useState<PetitionData[]>([])
  const [submissionsPerMinute, setSubmissionsPerMinute] = useState(0)
  const [velocity, setVelocity] = useState(0)
  const [currentTotal, setCurrentTotal] = useState(0)
  const [constituencies, setConstituencies] = useState<ConstituencyData[]>([])
  const [allConstituencies, setAllConstituencies] = useState<ConstituencyData[]>([])
  const [countries, setCountries] = useState<SignatureData[]>([])
  const [partyData, setPartyData] = useState<PartyData[]>([]);
  const [partyToal, setPartyTotal] = useState(0);
  const [regions, setRegions] = useState<SignatureData[]>([])
  const [regionTotal, setRegionTotal] = useState(0)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const pageVisible = useRef(true)
  const populationData = useRef<PopulationData>({})
  const mpData = useRef<MPData>({});

  useEffect(() => {
    const handleVisibilityChange = () => {
      pageVisible.current = !document.hidden
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const fetchPopulationData = async () => {
      try {
        const response = await fetch('https://petitionmap.unboxedconsulting.com/json/mps/current/population_ons.json')
        const data = await response.json()
        populationData.current = data
      } catch (err) {
        console.error('Failed to fetch population data:', err)
      }
    }

    const fetchMPData = async () => {
      try {
        const response = await fetch('https://petition.parliament.uk/parliaments/current/constituencies.json');
        const data = await response.json();
        mpData.current = data;
      } catch (err) {
        console.error('Failed to fetch MP data:', err)
      }
    }

    fetchPopulationData();
    fetchMPData();
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (!pageVisible.current) {
        console.log('Page not visible, fetching data in background')
      }
      try {
        // Fetch signature count
        const countResponse = await fetch('https://petition.parliament.uk/petitions/700143/count.json');
        const countData = await countResponse.json();
        const newCount = countData.signature_count;

        // Fetch full petition data including constituencies, countries, and regions
        const petitionResponse = await fetch('https://petition.parliament.uk/petitions/700143.json');
        const petitionData = await petitionResponse.json();

        // Breakdown by party
        let partyTotal = 0;
        let partyDataTemp: { [key: string]: number } = {};
        petitionData.data.attributes.signatures_by_constituency.forEach((data: any) => {
          const party = mpData.current[data.ons_code].party;

          if (partyDataTemp[party]) {
            partyDataTemp[party] += data.signature_count;
          } else {
            partyDataTemp[party] = data.signature_count;
          }
        });

        const partyDataFinal = Object.keys(partyDataTemp).map((party) => {
          partyTotal += partyDataTemp[party];
          return {
            party: party,
            count: partyDataTemp[party]
          }
        }).sort((a, b) => b.count - a.count);
        setPartyData(partyDataFinal);
        setPartyTotal(partyTotal);

        const constituencyData = Object.entries(petitionData.data.attributes.signatures_by_constituency)
          .map(([_, data]: [string, any]) => ({
            ons_code: data.ons_code,
            name: data.name,
            signature_count: data.signature_count,
            population: populationData.current[data.ons_code].population || 0,
            mp: mpData.current[data.ons_code].mp,
            party: mpData.current[data.ons_code].party,
            percentage: 0 // Will be calculated below
          }))
          .map(constituency => ({
            ...constituency,
            percentage: constituency.population > 0
              ? (constituency.signature_count / constituency.population) * 100
              : 0
          }))
          .sort((a, b) => b.percentage - a.percentage);

        setAllConstituencies(constituencyData);
        setConstituencies(constituencyData.slice(0, 20));

        const countryData = Object.entries(petitionData.data.attributes.signatures_by_country)
          .map(([_, data]: [string, any]) => ({
            name: data.name,
            signature_count: data.signature_count
          }))
          .sort((a, b) => b.signature_count - a.signature_count)
          .slice(0, 20);

        let regionTotalTemp = 0;
        petitionData.data.attributes.signatures_by_region.forEach((data: any) => {
          regionTotalTemp += data.signature_count;
        });
        setRegionTotal(regionTotalTemp);

        const regionData = Object.entries(petitionData.data.attributes.signatures_by_region)
          .map(([_, data]: [string, any]) => ({
            name: data.name,
            signature_count: data.signature_count
          }))
          .sort((a, b) => b.signature_count - a.signature_count)
          .slice(0, 20);

        setCountries(countryData);
        setRegions(regionData);

        setData(prevData => {
          const newData = [
            ...prevData,
            { count: newCount, timestamp: Date.now() }
          ].slice(-30)

          setCurrentTotal(newData[newData.length - 1].count)

          if (newData.length >= 2) {
            const timeSpan = (newData[newData.length - 1].timestamp - newData[0].timestamp) / 60000
            const countDiff = newData[newData.length - 1].count - newData[0].count
            setSubmissionsPerMinute(Math.max(0, Math.round(countDiff / timeSpan)))

            const recentTimeSpan = (newData[newData.length - 1].timestamp - newData[newData.length - 2].timestamp) / 60000
            const recentCountDiff = newData[newData.length - 1].count - newData[newData.length - 2].count
            setVelocity(Math.max(0, Math.round(recentCountDiff / recentTimeSpan)))
          }

          return newData
        })
        setIsLoading(false)
      } catch (err) {
        fetchData();
      }
    }

    fetchData()
    const interval = setInterval(fetchData, 10000)

    return () => clearInterval(interval)
  }, [])

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-red-500 font-semibold">{error}</p>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <p className="text-gray-500">Loading petition data...</p>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className=" text-white" style={{ backgroundColor: '#016548' }}>
        <div className="w-full lg:max-w-[90%] mx-auto px-4 py-16 sm:px-6 sm:py-14 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            Election Petition Tracker
          </h1>
          <p className="mt-6 max-w-3xl text-xl">
            Real-time tracking for the <a className='underline' href="https://petition.parliament.uk/petitions/700143">General Election Petition</a>.
          </p>
          <div className="mt-10 flex items-center space-x-4">
            <Clock className="h-6 w-6" />
            <span className="text-sm font-medium">Refreshes every 10 seconds</span>
          </div>
        </div>
      </div>

      <div className="w-full lg:max-w-[90%] mx-auto px-4 py-12 sm:px-6 lg:px-8">

        <div className="grid gap-6 grid-cols-1 md:grid-cols-4 mb-8">
          <Card className="bg-white shadow-sm lg:row-span-2 lg:text-center border-emerald-700">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-emerald-900 flex items-center font-weight-bold">
                <Users className="h-4 w-4 mr-2" />
                Total Signatures
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl lg:text-5xl lg:pt-14 font-semibold text-gray-900">{currentTotal.toLocaleString()}</div>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                <ArrowUpRight className="h-4 w-4 mr-2" />
                Submissions per Minute
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold text-gray-900">{submissionsPerMinute}</div>
            </CardContent>
          </Card>


          <Card className="bg-white shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                <TrendingUp className="h-4 w-4 mr-2" />
                Current Velocity
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold text-gray-900">{velocity}/min</div>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                <TrendingUp className="h-4 w-4 mr-2" />
                Projected total in 24 hours
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold text-gray-900">{(currentTotal + (velocity * 1440)).toLocaleString()}</div>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                <Contact className="h-4 w-4 mr-2" />
                2024 Labour Vote Count
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold text-gray-900">9,708,716</div>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                <Speech className="h-4 w-4 mr-2" />
                % of 2024 Voters
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold text-gray-900">{((currentTotal / 48200000) * 100).toFixed(2).toLocaleString()}%</div>

            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader className="pb-2">
              <CardTitle className="text-sm font-medium text-gray-500 flex items-center">
                <LandPlot className="h-4 w-4 mr-2" />
                Distance to Labour 2024 Votes
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-semibold text-gray-900">{(9708716 - currentTotal).toLocaleString()}</div>

            </CardContent>
          </Card>

        </div>

        <ConstituencySearch itemsData={allConstituencies} />

        <Card className="bg-white shadow-sm mb-8">
          <CardHeader>
            <CardTitle className="text-lg font-semibold text-gray-900">Signature Trend</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis
                    dataKey="timestamp"
                    tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                    stroke="#6B7280"
                  />
                  <YAxis
                    stroke="#6B7280"
                    domain={[(dataMin: number) => Math.floor(dataMin * 0.95), 'auto']}
                  />
                  <Tooltip
                    labelFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString()}
                    formatter={(value) => [`${value.toLocaleString()} signatures`, 'Count']}
                    contentStyle={{ backgroundColor: '#F3F4F6', border: 'none', borderRadius: '4px' }}
                  />
                  <Line
                    type="monotone"
                    dataKey="count"
                    stroke="#6366F1"
                    strokeWidth={2}
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </CardContent>
        </Card>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
          <Card className="bg-white shadow-sm lg:col-span-3">
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-gray-900 flex items-center">
                <MapPin className="h-5 w-5 mr-2" />
                Top Constituencies (by % of Population)
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Constituency</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Signatures</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Population</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Current Party</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Petition Percentage</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {constituencies.map((constituency, index) => (
                      <tr key={constituency.name}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index + 1}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{constituency.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{constituency.signature_count.toLocaleString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{constituency.population.toLocaleString()}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{constituency.party}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{constituency.percentage.toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-gray-900 flex items-center">
                <Globe className="h-5 w-5 mr-2" />
                Top Countries
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {countries.map((country, index) => (
                  <div key={country.name} className="flex items-center justify-between">
                    <div className="flex items-start space-x-3">
                      <div className="w-6 text-gray-500 font-medium">{index + 1}.</div>
                      <div className="font-medium text-gray-900">{country.name}</div>
                    </div>
                    <div className="font-semibold text-gray-900">
                      {country.signature_count.toLocaleString()}
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          <Card className="bg-white shadow-sm">
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-gray-900 flex items-center">
                <Flag className="h-5 w-5 mr-2" />
                Top Regions
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {regions.map((region, index) => (
                  <div key={region.name} className="flex items-center justify-between">
                    <div className="flex items-start space-x-3">
                      <div className="w-6 text-gray-500 font-medium">{index + 1}.</div>
                      <div className="font-medium text-gray-900">{region.name}</div>
                    </div>
                    <div className="font-semibold text-gray-900">
                      {region.signature_count.toLocaleString()} <small>{regionTotal > 0 ? `(${((region.signature_count / regionTotal) * 100).toFixed(2)}%)` : ''}</small>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>


          <Card className="bg-white shadow-sm">
            <CardHeader>
              <CardTitle className="text-lg font-semibold text-gray-900 flex items-center">
                <PartyPopper className="h-5 w-5 mr-2" />
                By Constituency Party
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {partyData.map((party, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <div className="flex items-start space-x-3">
                      <div className="w-6 text-gray-500 font-medium">{index + 1}.</div>
                      <div className="font-medium text-gray-900">{party.party}</div>
                    </div>
                    <div className="font-semibold text-gray-900">
                      {party.count.toLocaleString()} <small className='text-slate-500'>{partyToal > 0 ? `(${((party.count / partyToal) * 100).toFixed(2)}%)` : ''}</small>
                    </div>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

      </div>
    </div>
  )
}

