'use client'

import { useState } from 'react'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

// Simulated API call to get constituency code
const getConstituencyCode = async (postcode: string, data: any): Promise<string> => {
  const response = await fetch(`https://api.postcodes.io/postcodes/${postcode}`);
  const json = await response.json()

  let item = null;

  for (const constituency of data) {
    if (constituency.ons_code === json.result.codes.parliamentary_constituency_2024) {
      item = constituency;
    }
  }

  return {
    ...item,
    ...json.result
  }
}
export default function ConstituencyFinder({ itemsData }: { itemsData: any }) {
  const [postcode, setPostcode] = useState('')
  const [constituencyData, setConstituencyData] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setError('')
    setConstituencyData(null)

    try {
      const constituencyData = await getConstituencyCode(postcode, itemsData)
      setConstituencyData(constituencyData)
    } catch (err) {
      setError('Failed to fetch constituency data. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  console.log(constituencyData);

  return (
    <Card className="w-full bg-white mb-6 rounded-none grid lg:grid-cols-3" style={{ borderColor: '#016548' }}>
      <div className="bg-blue-700 text-white px-6 py-2 rounded-none content-center" style={{ backgroundColor: '#016548' }}>
        <CardTitle className="text-2xl font-bold">
          {constituencyData ? constituencyData.parliamentary_constituency_2024 : 'Get Data for your Constituency'}
        </CardTitle>
      </div>
      <CardContent className={`w-auto px-6 pb-6 pt-2 lg:col-span-2`} style={{ backgroundColor: constituencyData ? '#016548' : '#fff' }}>
        {!constituencyData && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="flex flex-col space-y-2">
              <label htmlFor="postcode" className="text-sm font-extrabold text-gray-700">Enter your postcode</label>
              <div className="flex space-x-2">
                <Input
                  id="postcode"
                  type="text"
                  value={postcode}
                  onChange={(e) => setPostcode(e.target.value)}
                  placeholder="e.g., SW1A 1AA"
                  className="flex-grow text-xl text-slate-800 bg-white py-8"
                />
                <Button type="submit" disabled={loading} className="hover:bg-blue-800 text-white px-6 py-8" style={{ backgroundColor: '#016548' }}>
                  {loading ? 'Searching...' : 'Get Data'}
                </Button>
              </div>
            </div>
          </form>
        )}

        {error && <p className="text-red-600 mt-4 text-center">{error}</p>}

        {constituencyData && (
          <div className="grid gap-6 grid-cols-1 md:grid-cols-3 mt-4">

            <Card className="bg-white shadow-sm border-emerald-700">
              <p className='text-slate-600 px-[23px] py-3'>Signatures</p>
              <CardContent>
                <div className="text-2xl font-semibold text-gray-900">{constituencyData.signature_count.toLocaleString()} <small className='text-slate-600'>({constituencyData.percentage.toFixed(2)}%)</small></div>
              </CardContent>
            </Card>

            <Card className="bg-white shadow-sm border-emerald-700">
              <p className='text-slate-600 px-[23px] py-3'>Population</p>
              <CardContent>
                <div className="text-2xl font-semibold text-gray-900">{constituencyData.population.toLocaleString()}</div>
              </CardContent>
            </Card>

            <Card className="bg-white shadow-sm border-emerald-700">
              <p className='text-slate-600 px-[23px] py-3'>Party</p>
              <CardContent>
                <div className="text-2xl font-semibold text-gray-900">{constituencyData.party}</div>
              </CardContent>
            </Card>


          </div>
        )}
      </CardContent>
    </Card>
  )
}
